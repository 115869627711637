
import { mapGetters } from 'vuex'

export default {
    data() {
        return {
            slideModel: 0,
            checkInterest: false,
            intervalCheck: null,
        }
    },
    computed: {
        ...mapGetters({
            featuredContent2Getter: 'cms/featuredContent2',
		}),
		featuredContent2() {
			return this.featuredContent2Getter(this.$config.THEME)
		}
    },
    beforeDestroy() {
        try {
            clearInterval(this.intervalCheck)
        } catch (error) {}
    },
    mounted() {
        this.setupInterval()
    },
    methods: {
        setItemModel(idx, url) {
            if (this.slideModel !== idx) {
                this.slideModel = idx
                clearInterval(this.intervalCheck)
                this.setupInterval()
            } else {
                this.openNewBrowserTab(url)
            }
        },
        setupInterval() {
            this.intervalCheck = setInterval(() => {
                if (
                    this.slideModel + 1 <
                    this.featuredContent2?.items?.length
                ) {
                    this.slideModel += 1
                } else {
                    this.slideModel = 0
                }
            }, 5000)
        },
        onIntersect(entries, observer) {
            const interesting = entries[0]?.isIntersecting
            if (interesting) {
                this.checkInterest = true
            }
        },
    },
}
