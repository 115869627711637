import { render, staticRenderFns } from "./FeaturedContent2.vue?vue&type=template&id=4984d80c&scoped=true"
import script from "./FeaturedContent2.vue?vue&type=script&lang=js"
export * from "./FeaturedContent2.vue?vue&type=script&lang=js"
import style0 from "./FeaturedContent2.vue?vue&type=style&index=0&id=4984d80c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4984d80c",
  null
  
)

export default component.exports